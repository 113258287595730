@font-face {
    font-family: "Didot";
    src: url("../../fonts/GFSDidot-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: "BonheurRoyale";
    src: url("../../fonts/BonheurRoyale-Regular.ttf") format("truetype");
  }

  @font-face {
    font-family: "Gill Sans";
    src: url("../../fonts/GILLSANS.ttf") format("truetype");
  }